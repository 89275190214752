﻿import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const connectionString = document.currentScript.dataset.connectionString;
const roleName = document.currentScript.dataset.roleName;
const roleInstance = document.currentScript.dataset.roleInstance;
const enableDebug = document.currentScript.dataset.dataDebug;

if (connectionString !== '' && connectionString !== null && connectionString !== void 0) {
  const clickPluginInstance = new ClickAnalyticsPlugin();

  // Click Analytics configuration
  const clickPluginConfig = {
    autoCapture: true
  };
  const appInsights = new ApplicationInsights({
    config: {
      connectionString,
      excludeRequestFromAutoTrackingPatterns: [
        /google/,
        /gstatic/,
        /doubleclick/,
        /tglyr/,
        /cookiepro/,
        /facebook/,
        /hubapi/,
        /hubspot/,
        /adform/,
        /hotjar/,
        /linkedin/
      ],
      extensions: [clickPluginInstance],
      extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig
      },
    }
  });

  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = roleName;
    envelope.tags['ai.cloud.roleInstance'] = roleInstance;

    if (enableDebug === '1') {
      console.debug('Application Insights', envelope);
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
