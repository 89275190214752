﻿import { requestLocationFromBrowser } from "./getLocation";

let autocomplete = null;
let geocoder = null;

export const onGoogleMapsReady = function () {
	console.debug('Initializing geocoder');
	geocoder = new google.maps.Geocoder();
}

export const initialize = async function (handler) {
	const input = document.getElementById('google-places-autocomplete');
	if (input === undefined || input === null) {
		return null;
	}

	const options = {
		types: ['(regions)'],
		componentRestrictions: { country: 'be' },
		fields: ['geometry', 'name'],
		strictBounds: false,
	};

	autocomplete = new google.maps.places.Autocomplete(input, options);
	autocomplete.addListener('place_changed', async () => {
		console.debug('Google.Autocomplete', 'place_changed');
		const place = autocomplete.getPlace() || {};
		console.debug('onAutoComplete', 'getPlace', place);
		const name = place.name;
		const geometry = place.geometry;

		if (geometry !== undefined && geometry !== null) {
			handler({ name, lat: geometry.location.lat(), lng: geometry.location.lng() });
		} 
		else if (place.name === '' || place.name === null || place.name === undefined) {
			handler({ name: null, lat: 0, lng: 0 });
		}
		else {
			console.warn('autocomplete only provided location, attempting to resolve coordinates');
			const { name, latitude, longitude } = await getLocation(place.name);

			handler({ name, lat: latitude, lng: longitude });
		}
	});
}

export const getLocation = async function (location) {
	if (geocoder === null) { 
		geocoder = new google.maps.Geocoder();
	}

	const geocodeOptions = {
		region: 'BE',
		language: document.documentElement.lang || 'nl',
	}
	
	if(location) {
		geocodeOptions.address = location;
	} else {
		const { coords: { latitude, longitude } } = await requestLocationFromBrowser();
		geocodeOptions.location = { 
			lat: latitude,
			lng: longitude
		};
	}
	
	if (geocoder === null)
		 await initialize();

	const { results } = await geocoder.geocode(geocodeOptions);

	const result = results[0];
	const components = result.address_components.filter(component => component.types.indexOf('locality') !== -1);
	const address = components.length > 0 ? components[0].long_name : result.formatted_address;
	return {
		name: address,
		latitude: result.geometry.location.lat(),
		longitude: result.geometry.location.lng()
	};
}
