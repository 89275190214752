﻿import './frontend/application-insights';
import { requestLocationFromBrowser } from "./frontend/location/getLocation";
import { initialize, getLocation, onGoogleMapsReady } from './frontend/location/google-maps';

window.agilitas = window.agilitas || {};
window.agilitas.getLocation = requestLocationFromBrowser;
window.agilitas.googleMaps = {
    initialize,
    getLocation,
    onGoogleMapsReady,
};